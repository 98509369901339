import { SxProps, Theme } from "@mui/material"
import "dayjs/locale/en"
import "dayjs/locale/fr"
export type ColorType = "primary" | "success" | "warning" | "error"

export const decode64AndParse = (base64: string) => {
    if (!base64) return ""
    return JSON.parse(window.atob(base64))
}

export const isValidURL = (url: string) =>
    new RegExp("^(https?:\\/\\/)?([A-Za-z0-9-]+\\.)+[A-Za-z]{2,6}(:\\d{1,5})?(\\/.*)?$", "i").test(url)

export const isValidEmail = (email: string) => {
    if (!email || email === undefined) return true
    return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)
}

export const withSxProp = (...sxProps: SxProps<Theme>[]): SxProps<Theme> =>
    sxProps.reduce(
        (prev, currentValue) => [
            ...(Array.isArray(prev) ? prev : [prev]),
            ...(Array.isArray(currentValue) ? currentValue : [currentValue]),
        ],

        [] as SxProps<Theme>
    )

const hasOwn = (data: object, key: string): boolean => {
    return key in data
}

export const objectToQueryString = (obj: any) => {
    if (typeof obj === "object" && Object.keys(obj)?.length > 0) {
        const params = new URLSearchParams()
        for (const key in obj) {
            if (hasOwn(obj, key)) {
                params.append(key, obj[key])
            }
        }
        return params.toString()
    }
    return ""
}

export const encodeBase64 = (base64: string) => {
    if (!base64) return ""
    return window?.btoa(base64)
}

const isObject = (object: any) => object != null && typeof object === "object"

export const isDeepEqual = (object1: any, object2: any) => {
    const objKeys1 = Object.keys(object1)
    const objKeys2 = Object.keys(object2)

    if (objKeys1.length !== objKeys2.length) return false

    for (const key of objKeys1) {
        const value1 = object1[key]
        const value2 = object2[key]

        const isObjects = isObject(value1) && isObject(value2)

        if ((isObjects && !isDeepEqual(value1, value2)) || (!isObjects && value1 !== value2)) {
            return false
        }
    }
    return true
}
