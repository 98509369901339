import env from "@/config/env"
import createCache from "@emotion/cache"
import { CacheProvider, ThemeProvider } from "@emotion/react"
import { createTheme } from "@mui/material"
import { ThemeProvider as ThemeMuiProvider } from "@mui/material/styles"
import { LicenseInfo } from "@mui/x-license-pro"
import { wrapCreateBrowserRouter } from "@sentry/react"
import { Suspense, createContext, useEffect, useLayoutEffect, useMemo, useState } from "react"
import { RouterProvider, createBrowserRouter } from "react-router-dom"
import RoutesList from "./routes"
import UserSessionStore from "./store/user-session"
import lightTheme from "./theme/light"
const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter)

const router = sentryCreateBrowserRouter(RoutesList)

export const ThemeModeContext = createContext({ toggleMode: () => undefined })

const App = () => {
    const [mode, setMode] = useState<"light" | "dark">("light")
    const themeMode = useMemo(
        () => ({
            toggleMode: () => {
                setMode((prevMode) => (prevMode === "light" ? "dark" : "light"))
                return undefined
            },
        }),
        []
    )

    const cache = createCache({
        key: "mui",
        prepend: true,
    })

    const theme = useMemo(
        () =>
            createTheme({
                shape: {
                    borderRadius: 8,
                },
                palette: {
                    mode,
                    ...(mode === "light" && lightTheme.palette),
                },
                typography: {
                    fontFamily: "Poppins",
                    allVariants: {
                        textTransform: "initial",
                    },
                    h1: {
                        fontFamily: "Poppins Medium",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "123.5%",
                        letterSpacing: "0.25px",
                    },
                    h2: {
                        fontFamily: "Poppins Medium",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "133.4%",
                    },
                    h3: {
                        fontFamily: "Poppins Medium",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "160%",
                        letterSpacing: "0.15px",
                    },
                    h4: {
                        fontFamily: "Poppins Medium",
                    },
                    h5: {
                        fontFamily: "Poppins Medium",
                    },
                    h6: {
                        fontFamily: "Poppins Medium",
                    },
                },
                ...(mode === "light" && { components: lightTheme.components }),
            }),
        [mode]
    )

    const { updateState } = UserSessionStore.getState()

    useEffect(() => {
        window.addEventListener("offline", () => updateState({ isConnected: false }))
        window.addEventListener("online", () => updateState({ isConnected: true }))

        // cleanup if we unmount
        return () => {
            window.removeEventListener("offline", () => updateState({ isConnected: false }))
            window.removeEventListener("online", () => updateState({ isConnected: true }))
        }
    }, [])

    useLayoutEffect(() => {
        LicenseInfo.setLicenseKey(env.MUIX_LICENSE)
    }, [])

    return (
        <ThemeModeContext.Provider value={themeMode}>
            <CacheProvider value={cache}>
                <ThemeProvider theme={theme}>
                    <ThemeMuiProvider theme={theme}>
                        <Suspense fallback="loading">
                            <RouterProvider router={router} />
                        </Suspense>
                    </ThemeMuiProvider>
                </ThemeProvider>
            </CacheProvider>
        </ThemeModeContext.Provider>
    )
}

export default App
