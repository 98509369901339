import { FeedbackFormType } from "@/components/form/feedback-form/useFeedbackForm"
import { CompanyService } from "@/services"
import CompanyStore from "@/store/company"
import UiStore from "@/store/ui"
import Cookies from "js-cookie"
import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import useNotification from "../useNotification"

const useFeedback = () => {
    const [step, setStep] = useState<number>(-1)
    const { reviewUrl, locationId, companyId, dislike } = CompanyStore()
    const { preferredLanguage } = UiStore()
    const { notif } = useNotification()
    const { t } = useTranslation()
    const [reviewSent, setReviewSent] = useState<boolean>(false)
    const [isSending, setIsSending] = useState<boolean>(false)
    const redirectToGoogle = useCallback(() => {
        if (reviewUrl) {
            Cookies.remove("feedback_metadata")
            window.location.href = reviewUrl
        }
    }, [reviewUrl])

    const openNextStep = useCallback(() => {
        setStep(1)
    }, [step])

    const sendReview = useCallback(
        async (formValue: FeedbackFormType) => {
            setIsSending(true)
            const response: any = await CompanyService.sendReview({
                content: formValue?.message,
                firstname: formValue?.name,
                lastname: "",
                location_id: locationId,
                company_id: companyId,
                email: formValue?.email,
                lang: preferredLanguage,
            })
            if (response?.updatedAt) {
                setReviewSent(true)
                setStep(0)
                Cookies.remove("feedback_metadata")
            } else {
                notif({
                    message: t("FEEDBACK.STEP_TWO.SNACKBAR_ERROR"),
                    type: "ERROR",
                })
            }
            setIsSending(false)
        },
        [locationId, companyId, preferredLanguage]
    )

    useEffect(() => {
        if (!dislike) {
            setStep(0)
        }
    }, [dislike])

    return {
        step,
        reviewSent,
        isSending,
        setStep,
        redirectToGoogle,
        openNextStep,
        sendReview,
    }
}

export default useFeedback
