import Notification from "@/components/notification"
import { CompanyService } from "@/services"
import LangService from "@/services/lang"
import CompanyStore from "@/store/company"
import NotificationStore from "@/store/notification"
import UiStore from "@/store/ui"
import { InfType } from "@/types/inf"
import { decode64AndParse } from "@/utils"
import Cookies from "js-cookie"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Outlet, useSearchParams } from "react-router-dom"

const ProvidersWithOutlet = () => {
    const { companyFetched, updateState: updateCompanyState } = CompanyStore()
    const { favicon, preferredLanguage, updateState } = UiStore()
    const { i18n } = useTranslation()
    const { update: updateNotifState } = NotificationStore()
    const [searchParams, setSearchParams] = useSearchParams()
    useEffect(() => {
        if (favicon) {
            const link = document.querySelector("link#favicon")
            if (link) link.setAttribute("href", favicon + "?v=2")
        }
    }, [favicon])

    useEffect(() => {
        function handleLanguageChange() {
            const language = LangService.preloadLanguage(navigator.languages)
            updateState({ preferredLanguage: language })
        }
        window.addEventListener("languagechange", handleLanguageChange)
        return () => {
            window.removeEventListener("languagechange", handleLanguageChange)
        }
    }, [])

    useEffect(() => {
        const language = LangService.preloadLanguage(navigator.languages)
        document.documentElement.lang = language
        Cookies.set("lang", language)
        updateState({ preferredLanguage: language })
    }, [])

    const getCompany = async (inf: InfType) => {
        const reseller = await CompanyService.getInfos(inf)

        CompanyService.setState({
            companyName: reseller?.company_name,
            companyLogo: reseller?.company_logo,
            locationId: reseller?.location_id,
            reviewUrl: reseller?.review_url,
            companyId: reseller?.id,
            isFetching: false,
            accessDenied: false,
        })
    }

    useEffect(() => {
        const query = searchParams.get("inf")
        if (query) {
            updateCompanyState({
                companyFetched: true,
            })
            for (const key of searchParams.keys()) {
                searchParams.delete(key)
            }
            setSearchParams(searchParams)
            try {
                const decodedInfo = decode64AndParse(query ?? "")
                if (decodedInfo?.id) {
                    getCompany(decodedInfo)
                }
                if (decodedInfo?.dislike === "1") {
                    updateCompanyState({
                        dislike: true,
                    })
                }
            } catch (error) {
                updateCompanyState({
                    accessDenied: true,
                })
                Cookies.remove("feedback_metadata")
                throw new Error(error)
            }
        } else if (Cookies.get("feedback_metadata")) {
            updateCompanyState({
                companyFetched: true,
            })
            const infos = JSON.parse(Cookies.get("feedback_metadata") ?? "") as InfType
            if (infos?.id) {
                getCompany(infos)
            }
            if (infos?.dislike === "1") {
                updateCompanyState({
                    dislike: true,
                })
            }
        } else if (!companyFetched) {
            updateCompanyState({
                isFetching: false,
                accessDenied: true,
            })
        }
        updateNotifState({ show: false })
    }, [searchParams])

    useEffect(() => {
        i18n.changeLanguage(preferredLanguage)
    }, [preferredLanguage, i18n])

    return (
        <React.Fragment>
            <Notification />
            <Outlet />
        </React.Fragment>
    )
}

export default ProvidersWithOutlet
