import { isValidEmail } from "@/utils"
import { zodResolver } from "@hookform/resolvers/zod"
import { useCallback, useMemo } from "react"
import { useForm, useWatch } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { z } from "zod"

export interface FeedbackFormType {
    name: string
    email: string
    message: string
}

export const MESSAGE_LENGTH_MAX = 1500

const useFeedbackForm = ({ submit }) => {
    const { t } = useTranslation()
    const schema = z.object({
        name: z.string().nonempty(t("FEEDBACK.STEP_TWO.FIELD_REQUIRED")),
        email: z
            .string()
            .email(t("FEEDBACK.STEP_TWO.INVALID_FORMAT_EMAIL"))
            .nonempty(t("FEEDBACK.STEP_TWO.FIELD_REQUIRED")),
        message: z.string().nonempty(t("FEEDBACK.STEP_TWO.FIELD_REQUIRED")),
    })
    type SchemaValuesType = z.infer<typeof schema>
    const { handleSubmit, formState, control } = useForm<SchemaValuesType>({
        defaultValues: {
            name: "",
            email: "",
            message: "",
        },
        resolver: zodResolver(schema),
        mode: "onTouched",
    })
    const formValue = useWatch({ control })
    const messageLength = useMemo(() => {
        return formValue?.message?.length
    }, [formValue?.message])

    const disableSubmit = useMemo(() => {
        return (
            !formValue?.name ||
            !formValue?.email ||
            !formValue?.message ||
            (formValue?.email && !isValidEmail(formValue?.email))
        )
    }, [formValue])

    const handleSuccessSubmit = useCallback(() => {
        submit(formValue as FeedbackFormType)
    }, [formValue])

    return {
        formState,
        control,
        formValue,
        messageLength,
        disableSubmit,
        handleSubmit,
        handleSuccessSubmit,
    }
}

export default useFeedbackForm
